import AspireLogo from './assets/Aspire-blue_pos.svg'
import { useState, useEffect } from 'react';

function StatusLabel({ title, value }) {
    return (
        <div className="statusLabel">
            <b>{title}</b> {value}
        </div>
    )
}

function BackgroundImage() {
    return <div className='bgContainer'>
        <div className='bgImage' />
        <div className='bgGradient' />
    </div>
}

export default function CoffeeMonitor() {
    const [data, setData] = useState(null)

    function validateAndSetData(newData) {
        if (newData === undefined || newData == null) {
            console.log("Did not receive any data")
        } else if (typeof newData.timestamp !== 'number') {
            console.log("Timestamp must be a number " + JSON.stringify(newData))
        } else if (typeof newData.weight !== 'number') {
            console.log("Weight must be a number " + JSON.stringify(newData))
        } else {
            setData(newData)
        }
    }
    
    var readableLevel
    var readableWeight
    var dateString
    if (data == null) {
        readableLevel = ""
        readableWeight = ""
        dateString = ""
    } else {
        if (data.weight < 100) {
            readableLevel = "NEJ"
        } else if (data.weight < 500) {
            readableLevel = "TYP"
        } else {
            readableLevel = "JA"
        }

        readableWeight = Math.round(data.weight) + " ml"

        var date = new Date(data.timestamp * 1000)
        dateString = date.toDateString() + " " + date.toLocaleTimeString()
    }

    useEffect(() => {
        fetch('/api/CoffeeLevel')
            .then(response => response.json())
            .then(json => validateAndSetData(json))
            .catch(error => console.error(error))
        }, []
    )

    return (
        <div className="coffee-root">
            <BackgroundImage />
            <img className="logo" src={AspireLogo} onClick={() => {location.href = "https://aspire.se/"}} />
            <div className="shortStatus">
                <div className="coffeeTitle">Finns det kaffe?</div>
                <div className="coffeeStatusShort">{readableLevel}</div>
            </div>
            <div className="coffeeStatusDetailed">
                <StatusLabel title={"Hur mycket?"} value={readableWeight} />
                <StatusLabel title={"När då?"} value={dateString} />
            </div>
        </ div>
    )
}